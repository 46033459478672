<header #header>  
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-4 col-3"> 
                    <div class="center">
                        <modulo posicao="logo"></modulo>
                    </div>    
                </div>
                <div class="col-lg-5 col-md-4 content-box-pesquisa">
                    <box-form-pesquisa></box-form-pesquisa>  
                </div>  
                <div class="col-lg-4 col-md-4 col-9"> 
                    <div class="lista-actions-content">
                        <ul class="lista-actions">   
                            <li>
                                <btn-lista-desejo></btn-lista-desejo>
                            </li>
                            <li>
                                <btn-usuario-login></btn-usuario-login>
                            </li>
                            <li>
                                <btn-cart></btn-cart>
                            </li>
                        </ul> 
                    </div>       
                </div>    
            </div>    
        </div>    
    </div>
    <div class="content-menu-mobile">
        <partial-menu classCustom="menu-mobile" class="container-menu-mobile"></partial-menu>
    </div>  
    <div class="content-menu">
        <div class="container">
            <partial-menu></partial-menu> 
        </div>    
    </div>
    <div class="content-box-pesquisa-mobile">
        <box-form-pesquisa></box-form-pesquisa>  
    </div>
</header>    