<div [ngSwitch]="template">
    <div class="btn-login" *ngSwitchCase="null">
        <a href="javascript::void();" (click)="toRoute('/minha-conta')" title="Minha Conta" class="content btn-header-action" *ngIf="usuario == null">
            <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/usuario.svg" width="16px" height="16px" alt="Usuário" />    
            <div class="text">
                Acessar<br>
                <strong>Minha Conta</strong>
            </div> 
        </a>
        <div class="content content-usuario" (click)="toRoute('/minha-conta/perfil')" *ngIf="usuario !== null">
            <a href="javascript::void();" class="foto btn-header-action">
                <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/usuario.svg" width="16px" height="16px" alt="Usuário" />    
                <div class="item">
                    Bem-vindo(a)<br>
                    <strong>{{nome}}</strong>
                </div>
            </a>
        </div>       
    </div>
    <div class="btn-login two" *ngSwitchCase="'two'"> 
        <button type="button" (click)="_open()">
            <span>Já tenho uma conta</span>
        </button> 
    </div>
    <div class="btn-login three" *ngSwitchCase="'three'">   
        <button type="button" (click)="_open(1)">
            <span>Clique Aqui</span>
        </button> 
    </div>     
</div>        
