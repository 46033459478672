<div class="btn-menu-content">
    <a href="javascript:void()" class="btn-menu-open fadeIn" (click)="_open()">
        <img width="40px" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/menu.svg" alt="Menu" />
    </a>
</div>  
<div class="btn-close wow fadeIn" data-wow-delay="0.5s" (click)="_close()" [ngClass]="{'show': openMenu}">
    <div class="content">
        <span class="material-icons">close</span>
    </div>
</div>     
<nav class="menu {{classCustom}}" #menu [ngClass]="{'show': openMenu,'hide':!openMenu}">
    <div class="menu-header">
        <img class="center" width="100px" height="32px" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/logo/logo-costa-azul.png" alt="Logo Costazul Turismo" />
    </div>    
    <ul>
        <li>
            <a routerLink="/" title="Costazul Turismo" routerLinkActive="active">   
                <span>Home</span>
            </a>    
        </li>
        <li *ngFor="let c of categorias">
            <a routerLink="/servicos/{{c.apelido}}" title="{{c.titulo}}" routerLinkActive="active">
                <span>{{c.titulo}}</span> 
            </a>    
        </li> 
        <li>
            <a routerLink="/destinos" title="Destinos" routerLinkActive="active">     
                <span>Destinos</span>  
            </a>    
        </li> 
        <li>
            <a routerLink="/sobre" title="Sobre a Costazul Turismo" routerLinkActive="active">   
                <span>Sobre a Costazul Turismo</span>
            </a>    
        </li> 
        <li>
            <a routerLink="/assistencia-maragogi" title="Assitência em Maragogi" routerLinkActive="active">   
                <span>Assistência em Maragogi</span>
            </a>     
        </li>
        <li>
            <a routerLink="/contato" title="Contato" routerLinkActive="active">   
                <span>Contato</span>
            </a>    
        </li> 
        <li class="visible-mobile li-meus-favoritos">
            <a routerLink="/meus-favoritos">   
                <img src="/images/icons/heart.svg" alt="Lista de Desejos" />
                <span>Meus Favoritos</span>
            </a>    
        </li>   
    </ul>    
</nav>
<div class="bg-overlay">
    <div class="overlay" [ngClass]="{'show': openMenu}"></div>  
</div>    
     
