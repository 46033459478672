import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';

declare var $:any;

@Component({
  selector: 'partial-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {
 
  @ViewChild("header") header:ElementRef;

  constructor(

  ) { }
  
  /**
   * 
   * Seta a Class Fixed para o topo
   * 
   */
   setTopFixed(){

    let header = this.header.nativeElement;

    $(window).scroll(function(){
        
        var fromTop = $(this).scrollTop();
        var windowWidth = $(window).width();
        
        if(fromTop > 100 && windowWidth > 960){
          header.classList.add("animated","fadeIn","fixed");
        }else{
          header.classList.remove("fixed","animated","fadeIn");
        }

    });
      

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    
  }
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    this.setTopFixed();
  }

}
