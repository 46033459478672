<footer>
    <section class="footer-content">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="list">
                        <div class="item">    
                            <partial-links-uteis></partial-links-uteis>
                        </div>       
                        <div class="item">
                            <modulo posicao="contato" template="footer"></modulo>
                            <modulo class="partial-rede-social" posicao="rede-social" template="footer"></modulo>
                        </div>  
                        <div class="item">   
                            <partial-menu-ajuda></partial-menu-ajuda>
                        </div>  
                    </div>
                </div>  
                <div class="col-md-4"> 
                    <modulo posicao="newsletter"></modulo>
                </div>
            </div>   
        </div> 
    </section> 
    <div class="info">
        <div class="container">
            <span>A <strong>Costazul Turismo</strong> lhe inspira a viver as melhores experiências na Costa dos Corais.</span>
        </div>    
    </div>  
    <div class="copyright">
        <div class="container">
            <div class="row">   
                <div class="col-md-12">
                    <div class="copy-text"> 
                        Todos os direitos reservados - <strong>Costazul Turismo</strong> - 2021 | Desenvolvido por 
                        <a href="https://www.digitalmovement.com.br" target="_blank" title="Digital Movement - Agência Digital">
                            Digital Movement 
                            <img width="20px" height="20px" src="/assets/digital-movement/logo-digital-movement-2.svg" alt="Digital Movement - Soluções em Tecnologia" />
                        </a>. 
                    </div> 
                </div> 
            </div>       
        </div>    
    </div>    
</footer>    