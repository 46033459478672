<div [ngSwitch]="template">
  <div class="modulo modulo-newsletter" *ngSwitchCase="null">
    <div class="modulo-content">
      <div class="titulo"> 
        <h3>{{data.titulo}}</h3>
        <h4 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h4>
      </div>  
      <div class="content">
        <form class="form form-custom" (submit)="send()" autocomplete="off" autocapitalize="off">
          <div class="box">
            <div class="item">
              <div class="form-group">
                <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Informe seu Nome" />
              </div>
              <div class="form-group">
                <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Seu E-mail" />
              </div> 
              <div class="form-group"> 
                <input type="tel" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="Seu WhatsApp" mask="(99) 99999-9999" />
              </div>
            </div>
            <div class="item">
              <img width="140px" height="100px" defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/logo/costa-azul.png" alt="Costa Azul" />
            </div>  
          </div>
          <div class="content-button">
            <div class="form-group"> 
              <button type="submit" class="btn-one" [disabled]="loader">
                <span>Assinar</span>
              </button>
            </div>  
          </div>  
        </form>
      </div>  
    </div>
  </div>
</div>  
<loader *ngIf="loader"></loader>
