<div class="produto-avaliacao-star" *ngIf="template == 1">     
      <ul>
            <li>
                  <div class="icon" *ngIf="nota.nota >= 1">
                        <i class="fa-solid fa-star"></i>
                  </div>
            </li>
            <li>
                  <div class="icon" *ngIf="nota.nota >= 2">
                        <i class="fa-solid fa-star"></i>
                  </div>
                  <div class="icon gray" *ngIf="nota.nota < 2">
                        <i class="fa-regular fa-star"></i>
                  </div>
            </li>
            <li>
                  <div class="icon" *ngIf="nota.nota >= 3">
                        <i class="fa-solid fa-star"></i>
                  </div>
                  <div class="icon gray" *ngIf="nota.nota < 3">
                        <i class="fa-regular fa-star"></i>
                  </div>
            </li>
            <li>
                  <div class="icon" *ngIf="nota.nota >= 4">
                        <i class="fa-solid fa-star"></i>
                  </div>
                  <div class="icon gray" *ngIf="nota.nota < 4">
                        <i class="fa-regular fa-star"></i>
                  </div>
            </li>
            <li>
                  <div class="icon" *ngIf="nota.nota >= 5">
                        <i class="fa-solid fa-star"></i>
                  </div>
                  <div class="icon gray" *ngIf="nota.nota < 5">
                        <i class="fa-regular fa-star"></i>
                  </div>
            </li>
      </ul>
      <div class="info">
            <strong>{{nota.nota}}</strong> de <strong>{{nota.total}}</strong> avaliações
      </div>
</div>
<div class="produto-avaliacao-star" *ngIf="template == 2">     
      <ul>
            <li>
                  <div class="icon" *ngIf="notaServico >= 1">
                        <i class="fa-solid fa-star"></i>
                  </div>
            </li>
            <li>
                  <div class="icon" *ngIf="notaServico >= 2">
                        <i class="fa-solid fa-star"></i>
                  </div>
                  <div class="icon gray" *ngIf="notaServico < 2">
                        <i class="fa-regular fa-star"></i>
                  </div>
            </li>
            <li>
                  <div class="icon" *ngIf="notaServico >= 3">
                        <i class="fa-solid fa-star"></i>
                  </div>
                  <div class="icon gray" *ngIf="notaServico < 3">
                        <i class="fa-regular fa-star"></i>
                  </div>
            </li>
            <li>
                  <div class="icon" *ngIf="notaServico >= 4">
                        <i class="fa-solid fa-star"></i>
                  </div>
                  <div class="icon gray" *ngIf="notaServico < 4">
                        <i class="fa-regular fa-star"></i>
                  </div>
            </li>
            <li>
                  <div class="icon" *ngIf="notaServico >= 5">
                        <i class="fa-solid fa-star"></i>
                  </div>
                  <div class="icon gray" *ngIf="notaServico < 5">
                        <i class="fa-regular fa-star"></i>
                  </div>
            </li>
            <li>
                  <strong class="nota">
                        {{notaServico}}
                  </strong>
            </li>
      </ul>
</div>