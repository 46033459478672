<nav class="breadcrumb-content">
  <div class="container">
    <ul class="breadcrumb">
      <li class="breadcrumb-item"
        *ngFor="let item of listItens;let i=index;let last=last;" [ngClass]="{'active':last}"
      >
        <a *ngIf="!last && !item.routerLink">{{item.text}}</a>
        <a [routerLink]="item.routerLink" *ngIf="!last && item.routerLink">{{item.text}}</a>
        <span class="divisor" *ngIf="!last">></span>
        <span *ngIf="last">{{item.text}}</span>
      </li>
    </ul>
    <div class="btn-save" *ngIf="btnSave"> 
      <btn-favorito [isFavorito]="isFavorito" template="two" [id]="produtoId"></btn-favorito> 
    </div>  
  </div>
</nav>