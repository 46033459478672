<div class="produto-item {{classCustom}}" itemscope itemtype="https://schema.org/Product">
  <div class="capa" (click)="_click()">
      <a *ngIf="produto.foto != null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">
        <img width="300px" height="250px" defaultImage="/images/produto/sem-foto/default-capa.jpg" [lazyLoad]="produto.foto" itemprop="image" [alt]="produto.nome" class="img-fluid" /> 
      </a>  
      <a *ngIf="produto.foto == null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
        <img width="300px" height="250px" itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" alt="Sem Foto" class="img-fluid" />
      </a>   
    </div>
    <div class="content"> 
      <div class="box box-content">
        <div class="titulo"> 
          <h4 itemprop="name">  
            {{produto.nome | max:maxText}}     
          </h4> 
        </div> 
        <div class="avaliacao" *ngIf="produto.nota_servico.nota > 0"> 
          <produto-avaliacao-star [nota]="produto.nota_servico"></produto-avaliacao-star>
        </div>
        <article *ngIf="produto.descricao_listagem !== null && produto.descricao_listagem !== ''" [innerHtml]="produto.descricao_listagem | safeHtml"></article>
      </div>
      <div class="box box-valor">   
        <produto-item-valor [servico]="produto" [valor]="produto.menor_tarifa"></produto-item-valor>
      </div> 
      <div class="box footer" (click)="_click()">   
        <a href="javascript::void();" class="btn-one full" title="{{produto.nome}}">
          COMPRAR
        </a>   
      </div>
      <span class="parcelas" *ngIf="produto.mostrar_parcelas">  
        Pague em até <strong>{{produto.parcelas}}x</strong> sem juros
      </span>
    </div>  
    <span style="display:none;" itemprop="sku">{{produto.id}}</span> 
</div>
  