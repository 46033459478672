<section class="modulo-loader {{classCustom}}">
    <div class="loader-wrap">
      <div class="content">
        <figure class="animated infinite pulse">
          <img src="/images/logos/logo-costa-azul.png" width="60px" height="60px" alt="Costa Azul - Turismo Receptivo" />
        </figure>   
        <span class="message">{{message}}</span> 
      </div>  
    </div>
    <div class="overlay"></div> 
</section>
    