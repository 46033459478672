<div class="avaliacao-item">
      <div class="header">
            <div class="icon">
                  <i class="fa-solid fa-user"></i>
            </div>
            <span class="name">{{data.nome}}</span>
      </div>
      <div class="content">
            <div class="content-avaliacoes">
                  <produto-avaliacao-star [notaServico]="data.nota_servico" template="2"></produto-avaliacao-star>
            </div>
            <h4>{{data.titulo}}</h4>
            <article [innerHtml]="data.observacao"></article>
      </div>
</div>      
