<div class="modulo modulo-avaliacoes">
      <div class="modulo-title" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <h3>{{data.titulo}}</h3>
                  <h4 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h4>
            </div>
      </div>
      <div class="modulo-content">
            <div class="container"> 
                  <div class="avaliacoes slider-itens" #slider>    
                        <div class="slider-item item-servicos" *ngFor="let a of data.avaliacoes"> 
                          <avaliacao-item [data]="a"></avaliacao-item>    
                        </div>    
                  </div>
                  <div class="swiper-button-prev sw-btn" (click)="prev()">
                        <img src="/images/icons/arrow-left.svg" alt="Arrow" />  
                  </div>
                  <div class="swiper-button-next sw-btn" (click)="next()">
                        <img src="/images/icons/arrow-right.svg" alt="Arrow" />
                  </div>
            </div>
      </div>
</div>