<div class="modulo banner-slider-slick animated fadeIn">
  <div class="swiper-button-prev sw-btn" (click)="prevSlide()">
    <span class="material-icons">
      keyboard_arrow_left
    </span>
  </div>
  <div class="swiper-button-next sw-btn" (click)="nextSlide()"> 
    <span class="material-icons">
      keyboard_arrow_right
    </span>
  </div>
  <div #banner class="banner-slider-slick-content">
    <div *ngFor="let i of data.banners;let y = index">
      <div class="item slider-item" *ngIf="!i.link_ativo">
        <img width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
      </div>  
      <div *ngIf="i.link_ativo" [ngSwitch]="i.link_tipo">
        <a [routerLink]="getLinkCategoria(i)" *ngSwitchCase="'3'">
          <div class="item slider-item">
            <img width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a>
        <a [routerLink]="getLinkProduto(i)" *ngSwitchCase="'2'">
          <div class="item slider-item">
            <img width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a> 
        <a [href]="i.link" [target]="i.link_target" *ngSwitchCase="'1'">
          <div class="item slider-item">
            <img width="100%" height="300px" [src]="i.imagem" [alt]="i.titulo"  class="img-fluid" />
          </div>  
        </a>   
      </div>
    </div>
  </div>
</div>
<div class="wave">
  <img src="/images/template/onda/onda-1.svg" alt="Onda" width="100%" height="50px" />
</div>


