import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-rede-social',
  templateUrl: './rede-social.component.html',
  styleUrls: ['./rede-social.component.scss']
})
export class RedeSocialComponent implements OnInit {

  @Input("data") data;
  @Input("template") template = null;
  @Input("classCustom") classCustom;

  public htmlTrip = `<div id="TA_cdsratingsonlywide624" class="TA_cdsratingsonlywide"><ul id="UIKOJjKxP" class="TA_links gBjWJJsCIL"><li id="fmzkx3" class="iwU4HtTrR8Qb"><a target="_blank" href="https://www.tripadvisor.com.br/Attraction_Review-g644400-d8261450-Reviews-Costazul_Turismo_Receptivo-Maragogi_State_of_Alagoas.html"><img src="https://www.tripadvisor.com.br/img/cdsi/img2/branding/v2/Tripadvisor_lockup_horizontal_secondary_registered-18034-2.svg" alt="TripAdvisor"/></a></li></ul></div><script async src="https://www.jscache.com/wejs?wtype=cdsratingsonlywide&amp;uniq=624&amp;locationId=8261450&amp;lang=pt&amp;border=false&amp;shadow=true&amp;backgroundColor=white&amp;display_version=2" data-loadtrk onload="this.loadtrk=true"></script>`;

  constructor() { }

  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit(){
    
  }

}
