<section class="page-home">  
  <modulo posicao="banner-slider" class="wow fadeIn"></modulo>-
  <modulo posicao="produto-destaque-1"></modulo>
  <img width="400px" height="355px" src="/images/template/logo/costa-azul.png" class="img-hero-1 wow slideInLeft" data-wow-delay="0.4s" imageDefault="/images/pixel/bg.png" alt="Costa Azul" />
  <div class="section-link-passeios">
    <div class="container">
      <a routerLink="/servicos" title="Serviços" class="btn-two center wow slideInRight" data-wow-delay="0.5s">
        CONFIRA MAIS PASSEIOS
      </a>     
    </div>
  </div>
  <modulo posicao="produto-destaque-2"></modulo>
  <modulo posicao="destinos" class="z-index-1"></modulo>
  <modulo posicao="fale-conosco"></modulo>
  <modulo posicao="avaliacao"></modulo> 
  <modulo posicao="contato" template="home"></modulo>
  <modulo posicao="rede-social" template="home"></modulo>
  <modulo posicao="whatsapp" template="fixo"></modulo> 
</section>   

 



