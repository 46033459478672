<div class="modulo">
      <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
            <div class="container">
                  <h2 class="section-title-h2 wow slideInUp" data-wow-delay="0.1s" [innerHtml]="data.titulo"></h2>
            </div>
      </div>
      <div class="modulo-content">
            <div class="container">
                  <div class="row">  
                        <div class="col-md-4 wow slideInUp" data-wow-delay="0.2.s" *ngFor="let p of data.produtos">
                              <produto-item [data]="p"></produto-item>
                        </div>      
                  </div>      
            </div>    
      </div>  
</div>      