import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'produto-avaliacao',
  templateUrl: './produto-avaliacao.component.html',
  styleUrls: ['./produto-avaliacao.component.scss']
})
export class ProdutoAvaliacaoComponent implements OnInit {

  @Input("data") data:any = {};

  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
