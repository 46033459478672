<div class="produto-avaliacao">
      <div class="title">
            <h3>Útimas avaliações</h3>
      </div>
      <div class="total">
            <ul>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 1">
                              <i class="fa-solid fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 2">
                              <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="icon gray" *ngIf="data.nota < 2">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 3">
                              <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="icon gray" *ngIf="data.nota < 3">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 4">
                              <i class="fa-solid fa-star"></i>
                        </div>
                        <div class="icon gray" *ngIf="data.nota < 4">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <div class="icon" *ngIf="data.nota >= 5">
                              <i class="fa-solid fa-star"></i>
                        </div>  
                        <div class="icon gray" *ngIf="data.nota < 5">
                              <i class="fa-regular fa-star"></i>
                        </div>
                  </li>
                  <li>
                        <strong class="nota">
                              {{data.nota}}
                        </strong>
                  </li>
            </ul>
            <div class="info">
                  <span>Baseado em </span> <strong>{{data.total}}</strong> <span> avaliações.</span>
            </div>
      </div>
      <div class="list">
            <div class="item" *ngFor="let l of data.lista">
                  <div class="box">
                        <div class="icon">
                              <i class="fa-solid fa-user"></i>
                        </div>
                        <span class="name">{{l.nome}}</span>
                        <span class="origem">{{l.origem}}</span>
                  </div>
                  <div class="box box-content">
                        <h4>{{l.titulo}}</h4>
                        <article [innerHtml]="l.observacao"></article>
                  </div>
                  <div class="box box-star">
                        <produto-avaliacao-star [notaServico]="l.nota_servico" template="2"></produto-avaliacao-star>
                  </div>
            </div>
      </div>
</div>