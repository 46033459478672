import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'modulo-produto-destaque-one',
  templateUrl: './produto-destaque-one.component.html',
  styleUrls: ['./produto-destaque-one.component.scss']
})
export class ProdutoDestaqueOneComponent implements OnInit {

  @Input("data") data; 
  @Input("classCustom") classCustom = ""; 
  @Input("template") template = null;  
  public produtos             = [];
  public categorias           = [];
  public produtosArray        = new Array();
  @ViewChild("slider") slider:ElementRef;
  
  constructor(){ }

  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {} 

}
