<div [ngSwitch]="template">
  <div *ngSwitchCase="null" class="single">
    <img width="20px" height="20px" src="/images/icons/alltour/whatsapp.svg" alt="Whatsapp" (click)="open()" />
  </div>   
  <div *ngSwitchCase="'fixo'" class="fixo wow fadeIn" data-wow-delay="2s" (click)="openModal()" #fixo>
    <div class="box-whats pulsing"> 
      <img defaultImage="/images/pixel/bg.png" lazyLoad="/images/template/icons/whatsapp.svg" alt="Whatsapp" width="20px" height="20px" />
    </div> 
  </div>  
  <div class="default-ajuda" *ngSwitchCase="'menu-ajuda'" (click)="open()">
    <div class="box-whats">
      <div class="content">
        <i class="fab fa-whatsapp"></i>
        <span>falar pelo WhatsApp</span>
      </div>  
    </div>
  </div>
</div>
