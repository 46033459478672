import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvaliacoesComponent } from 'src/app/modulos/avaliacoes/avaliacoes.component';
import { PartialModule } from '../../partial/partial.module';
import { AvaliacaoItemComponent } from 'src/app/components/avaliacao/avaliacao-item/avaliacao-item.component';
import { ProdutoPartialModule } from '../../produto/produto-partial/produto-partial.module';

@NgModule({
  declarations: [
    AvaliacoesComponent,
    AvaliacaoItemComponent
  ],
  exports:[
    AvaliacoesComponent,
    AvaliacaoItemComponent
  ],
  imports: [
    CommonModule,
    PartialModule,
    ProdutoPartialModule
  ]
})
export class ModuloAvaliacaoModule{}
