import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'avaliacao-item',
  templateUrl: './avaliacao-item.component.html',
  styleUrls: ['./avaliacao-item.component.scss']
})
export class AvaliacaoItemComponent implements OnInit {

  @Input("data") data:any = {};

  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
